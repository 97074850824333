@import '@aurora/shared-client/styles/_variables.pcss';

.lia-date-icon {
  border: 1px solid var(--lia-bs-border-color);
  background-color: var(--lia-bs-white);
  text-align: center;
  height: 50px;
  width: 50px;
  border-radius: var(--lia-bs-border-radius);
  overflow: hidden;

  @media (--lia-breakpoint-down-md) {
    height: 40px;
    width: 40px;
  }
}

.lia-month {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--lia-font-size-xs);
  font-weight: var(--lia-bs-font-weight-bold);
  text-transform: uppercase;
  background-color: var(--lia-bs-gray-300);
  height: 18px;
  color: var(--lia-occasion-color);

  @media (--lia-breakpoint-down-md) {
    height: 14px;
    font-size: var(--lia-font-size-xs);
  }
}

.lia-date {
  font-size: var(--lia-bs-h5-font-size);
  font-weight: var(--lia-font-weight-md);
  color: var(--lia-bs-gray-900);
  height: 32px;

  @media (--lia-breakpoint-down-md) {
    height: 26px;
    font-size: var(--lia-bs-h6-font-size);
  }
}
